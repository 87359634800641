<template>
  <nav ref="rootEl" class="menu" :class="{ float }">
    <NavLink v-if="shouldShowPlanTripBtn" class="plan-trip" href="/plan-a-trip">Plan a trip</NavLink>

    <NavLink v-if="desktop && !user" class="link signin" href="/users/sign_in">Sign in</NavLink>

    <div v-if="mobile || user" class="user">
      <button v-if="user" class="user-btn" @click="openPopup = !openPopup">
        <img class="photo" :src="user.photoUrl" alt="user photo">
        <i class="icon icon-arrow-down-1" :class="{ up: openPopup }"/>
      </button>

      <button v-else class="menu-btn" @click="openPopup = !openPopup">
        <i class="icon icon-navigation-menu-1"></i>
      </button>

      <Teleport :disabled="desktop" to="body">
        <transition name="popup">
          <div v-if="openPopup" ref="popupEl" class="header-popup" :class="{ loggedin: user }">
            <div v-if="user" class="greeting">Hi {{ displayUser }}</div>
            <div class="links">
              <NavLink v-if="isAdmin" href="/admin">Admin dashboard</NavLink>
              <NavLink v-if="editItineraryUrl" :href="editItineraryUrl">Edit itinerary</NavLink>
              <NavLink v-if="isOperator" href="/operators">Operator dashboard</NavLink>

              <NavLink v-if="!user" href="/users/sign_in">Sign in</NavLink>
              <NavLink v-if="user" href="/travelers">Dashboard</NavLink>
              <NavLink v-if="user" href="/users/edit">Edit profile</NavLink>
              <NavLink v-if="user" href="/travelers/your-trips">Your trips</NavLink>

              <div v-if="mobile" class="link-group">
                <NavLink class="link" href="/explore">Explore</NavLink>
                <NavLink class="link" href="/about">About</NavLink>
                <NavLink class="link" href="/en/magazine/">Magazine</NavLink>
                <NavLink class="link" href="/about#contact_us">Contact</NavLink>
              </div>

            </div>
            <div class="socials">
              <div>Follow Us</div>
              <div class="icons">
                <SocialLinks/>
              </div>
            </div>
            <button v-if="user" class="logout" @click="$emit('logout')">
              <span>Logout</span>
              <i class="icon icon-logout menu__logout-icon"/>
            </button>
          </div>
        </transition>
      </Teleport>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import NavLink from "@/components/NavLink.vue";
import { pathnameWithoutLangPrefix } from "@/utils/stringUtils.js";
import { eraseCookie, getCookieValue } from '@/utils/cookieUtils.js';
import { useAuthUserState } from "@/stores/authUser.js";
import SocialLinks from "@/components/SocialLinks.vue";
import { onClickOutside, syncRef } from '@vueuse/core'
import { metaTagContent } from "@/helpers/documentHelpers.js";
import { useShared } from "@/helpers/vueHelpers.js";
import { useSiteHeaderStore } from "@/stores/siteHeader.js";
import { storeToRefs } from "pinia";

defineProps({
  float: {
    type: Boolean,
    default: false
  }
});
defineEmits(['logout']);

const openPopup = ref(false);
const { mobile, desktop } = useShared();
const user = ref(null);
const rootEl = ref(null);
const popupEl = ref(null);
const authUserState = useAuthUserState();
const actualUser = ref('');
const currentUser = computed(() => user.value?.name?.split(' ')[0] || '');
const displayUser = computed(() => currentUser.value + (actualUser.value ? `(${actualUser.value})` : ''));
const isAdmin = ref(false);
const isOperator = ref(false);
const { forceShow } = storeToRefs(useSiteHeaderStore());

const shouldShowPlanTripBtn = computed(() => {
  const path = pathnameWithoutLangPrefix(location.pathname);
  return path !== '/' && path !== '/plan-a-trip';
});

const editItineraryUrl = computed(() => {
  const itineraryId = metaTagContent('itineraryId')
  return isAdmin.value && itineraryId ? `/admin/products/${itineraryId}?context=itinerary` : null;
});

syncRef(openPopup, forceShow, { direction: 'ltr' });

onClickOutside(popupEl, () => openPopup.value = false, { ignore: [rootEl] });

onMounted(async () => {
  if (getCookieValue('_tourhero_loggedin_cache') === "loggedin") {
    // if cookie present, then we may or may not be logged in, call API to ensure
    try {
      const result = await authUserState.fetch();
      if (result) {
        user.value = result;
        actualUser.value = user.value.meta.actualUser;
        isAdmin.value = user.value.meta.isAdmin;
        isOperator.value = user.value.meta.roles.includes('operator');
      }
    } catch (err) {
      console.error(err);
      eraseCookie('_tourhero_loggedin_cache');
    }
  }
});
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  align-items: center;
  gap: 12px;

  .link, .plan-trip {
    @include base-font;
    padding: 12px 20px;
    border-radius: 30px;
  }

  .link:hover {
    color: $grey-300;
    background-color: $grey-25;
  }

  .plan-trip {
    color: white;
    background-color: $brand-secondary;
  }
}

.menu.float {
  .link:hover {
    background-color: white;
  }

  .user > button {
    background-color: white;
  }

  .user::v-deep(.header-popup) {
    top: calc(100% + 16px);
  }
}

.user > button,
.signin {
  border: 1px solid $grey-50;
}

.user {
  position: relative;

  & > button {
    display: flex;
    align-items: center;
    border-radius: 30px;
    color: $black;
  }

  .user-btn {
    padding: 8px 12px;
    gap: 7px;
  }

  .menu-btn {
    padding: 12px 20px;

    & > i {
      font-size: 22px;
      line-height: 22px;
      transform: translateY(1px);
    }
  }

  .photo {
    width: 30px;
    aspect-ratio: 1/1;
    border-radius: 50%;
  }

  .icon-arrow-down-1 {
    font-size: 12px;
    transition: transform 200ms linear;

    &.up {
      transform: rotate(180deg) translateY(2px);
    }
  }
}
</style>

<style lang="scss">
.header-popup {
  @include base-font;
  --mobile-header-height: 72px;

  z-index: 2800;
  min-width: 258px;
  position: absolute;
  top: calc(100% + 28px);
  right: 0;
  border-radius: 15px;
  border: 1px solid $grey-25;
  background: #FFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include mobile {
    position: fixed;
    right: 0;
    top: var(--mobile-header-height);
    bottom: 0;
    overflow-y: auto;
    border-radius: 0;
    border: none;
    padding: 32px 24px;
    background: rgba(255, 255, 255, 0.70);
    box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(20px);

    .greeting, .links, .logout {
      font-size: 16px;
      line-height: 24px;
    }

    &.loggedin .link-group {
      margin-top: 16px;
    }
  }

  .links, .link-group {
    display: grid;
    gap: 16px;
  }

  .socials {
    text-transform: uppercase;
    font-weight: 700;
    display: grid;
    gap: 16px;

    @include mobile {
      color: #222;
      font-size: 12px;
      font-weight: 700;
      line-height: 150%; /* 18px */
    }

    .icons {
      display: flex;
      gap: 20px;

      a {
        width: 20px;
      }
    }
  }

  .logout {
    display: flex;
    gap: 12px;
    color: $gray;
  }
}

.popup-enter-active, .popup-leave-active {
  transition-duration: 200ms;
  transition-timing-function: ease-in;
}

.popup-enter-from, .popup-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.popup-enter-to, .popup-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>